// src/state/reducers/loading.js
import * as types from '../actionTypes'

const loadingReducer = (state = false, action) => {
    switch (action.type) {
        case types.START_LOADING:
            return true
        case types.STOP_LOADING:
            return false
        default:
            return state
    }
}

export default loadingReducer
