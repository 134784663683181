import React from 'react';
import {Field} from 'formik';
import StyledDatePicker from "../../components/StyledDatepicker";

const FormField = ({fieldConfig, errors, touched, setFieldValue, values}) => {
    const {name, label, type, options, placeholder} = fieldConfig;

    const getFieldComponent = () => {
        switch (type) {
            case 'textarea':
                return (
                    <Field
                        as="textarea"
                        name={name}
                        className={`w-full px-3 py-2 bg-white dark:bg-gray-900 border rounded-lg 
                        ${errors[name] && touched[name]
                            ? 'border-red-500 dark:border-red-500'
                            : 'border-gray-300 dark:border-gray-600'
                        } text-gray-900 dark:text-gray-100`}
                        rows="3"
                    />
                );
            case 'select':
                return (
                    <Field
                        as="select"
                        name={name}
                        className={`w-full px-3 py-2 bg-white dark:bg-gray-900 border rounded-lg
                        ${errors[name] && touched[name]
                            ? 'border-red-500 dark:border-red-500'
                            : 'border-gray-300 dark:border-gray-600'
                        } text-gray-900 dark:text-gray-100`}
                    >
                        <option value="">{placeholder}</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Field>
                );
            case 'date':
                return (
                    <StyledDatePicker
                        selected={values[name]}
                        onChange={(date) => setFieldValue(name, date)}
                        placeholderText={placeholder}
                        errors={errors[name]}
                        touched={touched[name]}
                    />
                );
            default:
                return (
                    <Field
                        type={type}
                        name={name}
                        className={`w-full px-3 py-2 bg-white dark:bg-gray-900 border rounded-lg
                        ${errors[name] && touched[name]
                            ? 'border-red-500 dark:border-red-500'
                            : 'border-gray-300 dark:border-gray-600'
                        } text-gray-900 dark:text-gray-100`}
                    />
                );
        }
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                {label}
            </label>
            {getFieldComponent()}
            {errors[name] && touched[name] && (
                <div className="text-red-500 text-sm mt-1">{errors[name]}</div>
            )}
        </div>
    );
};

export default FormField;
