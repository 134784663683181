import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideSnackbar} from "../../state/actions";

const Snackbar = () => {
    const dispatch = useDispatch();
    const {open, message, severity, duration} = useSelector((state) => state.persistedReducer.rootReducer.snackbar);

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                dispatch(hideSnackbar());
            }, duration);

            return () => clearTimeout(timer);
        }
    }, [open, duration, dispatch]);

    if (!open) return null;

    const severityClasses = {
        error: 'bg-red-500',
        success: 'bg-green-500',
        warning: 'bg-yellow-500',
        info: 'bg-blue-500',
    };

    return (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 animate-fade-up">
            <div
                className={`${severityClasses[severity]} text-white px-6 py-3 rounded-lg shadow-lg flex items-center justify-between`}>
                <span className="text-sm">{message}</span>
                <button
                    onClick={() => dispatch(hideSnackbar())}
                    className="ml-4 text-white hover:opacity-80 focus:outline-none"
                >
                    <svg
                        className="w-4 h-4"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Snackbar;
