import React from 'react';
import {useTranslation} from 'react-i18next';

const Dashboard = () => {
    const {t} = useTranslation();

    return (
        <div
            className="min-h-[90vh] bg-white dark:bg-gray-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-primary-light-text dark:text-primary-dark-text">
                        {t('dashboard.title')}
                    </h2>
                    <p className="mt-2 text-sm text-primary-light-text/80 dark:text-primary-dark-text/80">
                        {t('dashboard.welcomeMessage')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
