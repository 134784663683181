import * as Yup from "yup";

const getValidationSchema = (t) => Yup.object({
    email: Yup.string()
        .email(t('login.errors.emailInvalid'))
        .required(t('login.errors.required')),
    password: Yup.string()
        .required(t('login.errors.required'))
});
export default getValidationSchema
