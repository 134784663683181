import React, {useEffect, useRef, useState} from 'react';
import {IonIcon} from "@ionic/react";
import {chevronDownOutline, globeOutline} from 'ionicons/icons';
import i18n from '../../i18n';

const LanguageSwitcher = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentLang, setCurrentLang] = useState(() => {
        return localStorage.getItem('i18nextLng') || 'en';
    });
    const dropdownRef = useRef(null);

    const languages = {
        en: {
            name: 'English',
            countryCode: 'gb'
        },
        el: {
            name: 'Greek',
            countryCode: 'el'
        }
    };

    // Set language on initial load
    useEffect(() => {
        if (i18n.language !== currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }, [currentLang]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const changeLanguage = (lng) => {
        if (lng !== currentLang) {
            i18n.changeLanguage(lng);
            setCurrentLang(lng);
            localStorage.setItem('i18nextLng', lng);
            console.log("lang", lng)// Store the chosen language in localStorage
        }
        setIsOpen(false);
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center gap-2 text-primary-light-text dark:text-primary-dark-text hover:text-primary-light-tint dark:hover:text-primary-dark-tint transition-colors"
            >
                <IonIcon
                    icon={globeOutline}
                    className="w-4 h-4"
                />
                <IonIcon
                    icon={chevronDownOutline}
                    className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                />
            </button>

            {isOpen && (
                <div
                    className="absolute right-0 mt-2 w-40 bg-white/80 dark:bg-gray-900/80 backdrop-blur-md rounded-lg shadow-lg border border-primary-light-border dark:border-primary-dark-border">
                    {Object.entries(languages).map(([code, {name}]) => (
                        <button
                            key={code}
                            onClick={() => changeLanguage(code)}
                            className="flex items-center gap-2 w-full px-4 py-2 text-left text-primary-light-text dark:text-primary-dark-text hover:text-primary-light-tint dark:hover:text-primary-dark-tint hover:bg-gray-100/50 dark:hover:bg-gray-800/50 transition-colors"
                        >
                            <span>{name}</span>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;
