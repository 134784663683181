import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {routePath} from '../../config/routePath';
import {IonIcon} from '@ionic/react';
import {logoFacebook, logoInstagram, logoLinkedin, logoTwitter} from 'ionicons/icons';
import logoImg from "../../assets/logos/logo-color.png";

const Footer = () => {
    const {t} = useTranslation();

    return (
        <footer
            className="bg-white dark:bg-gray-900 border-t border-primary-light-border dark:border-primary-dark-border">
            <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div className="col-span-1">
                        <img
                            src={logoImg}
                            alt="Eco-Heroes Logo"
                            className="h-12 w-auto mb-4"
                        />
                        <p className="text-primary-light-text/80 dark:text-primary-dark-text/80">
                            {t('footer.companyDescription')}
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div className="col-span-1">
                        <h3 className="text-lg font-semibold text-primary-light-text dark:text-primary-dark-text mb-4">
                            {t('footer.quickLinks')}
                        </h3>
                        <ul className="space-y-3">
                            <li>
                                <NavLink
                                    to={routePath.termsOfUse}
                                    className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint"
                                >
                                    {t('footer.termsOfService')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={routePath.contactUs}
                                    className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint"
                                >
                                    {t('footer.contactUs')}
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* Contact Info */}
                    <div className="col-span-1">
                        <h3 className="text-lg font-semibold text-primary-light-text dark:text-primary-dark-text mb-4">
                            {t('footer.contact')}
                        </h3>
                        <ul className="space-y-3 text-primary-light-text/80 dark:text-primary-dark-text/80">
                            <li>support@eco-heroes.com</li>
                        </ul>
                    </div>

                    {/* Social Links */}
                    <div className="col-span-1">
                        <h3 className="text-lg font-semibold text-primary-light-text dark:text-primary-dark-text mb-4">
                            {t('footer.followUs')}
                        </h3>
                        <div className="flex space-x-4">
                            <a href="#"
                               className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint">
                                <IonIcon icon={logoFacebook} className="w-6 h-6"/>
                            </a>
                            <a href="#"
                               className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint">
                                <IonIcon icon={logoTwitter} className="w-6 h-6"/>
                            </a>
                            <a href="#"
                               className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint">
                                <IonIcon icon={logoInstagram} className="w-6 h-6"/>
                            </a>
                            <a href="#"
                               className="text-primary-light-text/80 dark:text-primary-dark-text/80 hover:text-primary-light-tint dark:hover:text-primary-dark-tint">
                                <IonIcon icon={logoLinkedin} className="w-6 h-6"/>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-8 pt-8 border-t border-primary-light-border dark:border-primary-dark-border">
                    <p className="text-center text-primary-light-text/60 dark:text-primary-dark-text/60">
                        © {new Date().getFullYear()} Eco-Heroes. {t('footer.allRightsReserved')}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
