// ErrorMessage.js
import React from 'react';
import {Field} from 'formik';

const ErrorMessage = ({name}) => (
    <Field name={name}>
        {({form}) => {
            const error = form.errors[name];
            const touched = form.touched[name];
            return touched && error ? (
                <p className="text-red-500 text-xs mt-0.5">{error}</p>
            ) : null;
        }}
    </Field>
);

export default ErrorMessage;
