// sagas/authSaga.js
import {call, put, takeLatest} from 'redux-saga/effects';
import {LOGIN_REQUEST} from '../actionTypes';
import * as actions from '../actions';
import {loginFailure, loginSuccess} from '../actions';
import api from "../../libs/api";
import {accessTokenName} from "../../config/constants";
import {apiEndpoints} from "../../config/apiEndpoints";

function* handleLogin(action) {
    try {
        yield put(actions.startLoading())
        const response = yield call(api.post, apiEndpoints.login, action.payload);
        const data = response.data;
        localStorage.setItem(accessTokenName, data.access);
        yield put(loginSuccess(data));
    } catch (error) {
        yield put(loginFailure(error.message));
    } finally {
        yield put(actions.stopLoading())
    }
}

export default function* watchAuthentication() {
    yield takeLatest(LOGIN_REQUEST, handleLogin);
}
