import React from 'react';
import {useTranslation} from 'react-i18next';

const TermsOfUse = () => {
    const {t} = useTranslation();

    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="relative">
                {/* Terms of Use Section */}
                <section
                    className="min-h-[calc(100vh-4rem)] flex flex-col items-center justify-center px-4 my-8"
                >
                    <div className="text-center max-w-4xl mx-auto">
                        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-primary-light-text dark:text-primary-dark-text">
                            {t('terms.title')}
                        </h1>
                        <div
                            className="text-left text-lg sm:text-xl mb-8 text-primary-light-text dark:text-primary-dark-text">
                            {/* Section 1 */}
                            <h2 className="text-3xl font-bold mb-4">{t('terms.joining.title')}</h2>
                            <p>{t('terms.joining.content1')}</p>
                            <p>{t('terms.joining.content2')}</p>
                            <p>{t('terms.joining.content3')}</p>

                            {/* Section 2 */}
                            <h2 className="text-3xl font-bold mb-4 mt-8">{t('terms.covered.title')}</h2>
                            <p>{t('terms.covered.content1')}</p>
                            <p>{t('terms.covered.content2')}</p>

                            {/* Section 3 */}
                            <h2 className="text-3xl font-bold mb-4 mt-8">{t('terms.using.title')}</h2>
                            <p>{t('terms.using.content1')}</p>
                            <p>{t('terms.using.content2')}</p>

                            {/* Section 4 */}
                            <h2 className="text-3xl font-bold mb-4 mt-8">{t('terms.mysteryBags.title')}</h2>
                            <p>{t('terms.mysteryBags.content1')}</p>

                            {/* Additional sections follow the same structure */}
                            <h2 className="text-3xl font-bold mb-4 mt-8">{t('terms.pricePaymentRefunds.title')}</h2>
                            <p>{t('terms.pricePaymentRefunds.content1')}</p>

                            <h2 className="text-3xl font-bold mb-4 mt-8">{t('terms.contact.title')}</h2>
                            <p>{t('terms.contact.content')}</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default TermsOfUse;
