import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const StyledDatePicker = ({selected, onChange, errors, touched, ...props}) => {
    const calendarClassName = "bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-lg rounded-lg border border-gray-300 dark:border-gray-600";
    const inputClassName = `w-full px-3 py-2 bg-white dark:bg-gray-900 border rounded-lg
    ${errors && touched
        ? 'border-red-500 dark:border-red-500'
        : 'border-gray-300 dark:border-gray-600'
    } text-gray-900 dark:text-gray-100`;

    return (
        <div className="react-datepicker-wrapper">
            <DatePicker
                selected={selected}
                onChange={onChange}
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                calendarClassName={calendarClassName}
                className={inputClassName}
                wrapperClassName="w-full"
                dayClassName={date =>
                    'hover:bg-primary-100 dark:hover:bg-primary-800'
                }
                {...props}
            />
            <style jsx={"true"} global={"true"}>{`
                .react-datepicker {
                    font-family: inherit;
                }

                .react-datepicker__header {
                    background-color: rgb(var(--primary-600));
                    border-bottom: none;
                }

                .react-datepicker__current-month,
                .react-datepicker__day-name {
                    color: inherit;
                }

                .react-datepicker__day--selected,
                .react-datepicker__day--keyboard-selected {
                    background-color: rgb(var(--primary-600));
                    color: white;
                }

                .react-datepicker__day--selected:hover,
                .react-datepicker__day--keyboard-selected:hover {
                    background-color: rgb(var(--primary-700));
                }

                .dark .react-datepicker__day {
                    color: #fff;
                }

                .dark .react-datepicker__day:hover {
                    background-color: rgb(var(--primary-800));
                }

                .dark .react-datepicker__current-month,
                .dark .react-datepicker__day-name {
                    color: #fff;
                }
            `}</style>
        </div>
    );
};

export default StyledDatePicker;
