import * as Yup from 'yup';


const getValidationSchema = (t) => Yup.object({
    firstName: Yup.string().required(t('business.signup.errors.required')),
    lastName: Yup.string().required(t('business.signup.errors.required')),
    email: Yup.string().email(t('business.signup.errors.emailInvalid')).required(t('business.signup.errors.required')),
    password: Yup.string().min(8, t('business.signup.errors.passwordLength')).required(t('business.signup.errors.required')),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('business.signup.errors.passwordMismatch')).required(t('business.signup.errors.required')),
    phoneNumber: Yup.string()
        .matches(/^[0-9]{10}$/, t('business.signup.errors.phoneInvalid'))
        .required(t('business.signup.errors.required'))
});

export default getValidationSchema;
``
