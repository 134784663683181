// BusinessSignup.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik} from 'formik';
import FormField from './formField';
import {lockClosedOutline, mailOutline, personOutline, phonePortraitOutline} from 'ionicons/icons';
import getValidationSchema from "./validator";
import api from "../../libs/api";
import {apiEndpoints} from "../../config/apiEndpoints";
import {showSnackbar, startLoading, stopLoading} from "../../state/actions";
import {useDispatch} from "react-redux";
import {createSearchParams, useNavigate} from "react-router-dom";
import {routePath} from "../../config/routePath";
import handleErrors from "../../libs/handleErrors";

const BusinessSignup = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: ''
    };
    const validationSchema = getValidationSchema(t);

    const handleSubmit = async (values, {setSubmitting, setFieldError}) => {
        try {
            dispatch(startLoading());

            const response = await api.post(apiEndpoints.createUser, values);
            dispatch(showSnackbar(t('business.signup.success'), 'success'));
            navigate({
                pathname: routePath.emailConfirmation,
                search: createSearchParams({email: values.email}).toString()
            });
        } catch (error) {
            handleErrors(error, dispatch, setFieldError, t);
        } finally {
            dispatch(stopLoading());
            setSubmitting(false);
        }
    };
    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="min-h-[90vh] flex items-center justify-center px-4 pb-16">
                <div className="w-full max-w-2xl">
                    <h1 className="text-2xl font-bold mb-2 text-primary-light-text dark:text-primary-dark-text text-center">
                        {t('business.signup.title')}
                    </h1>
                    <p className="text-sm mb-4 text-primary-light-text/80 dark:text-primary-dark-text/80 text-center">
                        {t('business.signup.description')}
                    </p>
                    <div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-lg">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({isSubmitting}) => (
                                <Form className="space-y-4">
                                    <div className="space-y-3">
                                        <FormField
                                            name="firstName"
                                            icon={personOutline}
                                            label={t('business.signup.userForm.firstName')}
                                            placeholder={t('business.signup.userForm.firstNamePlaceholder')}
                                        />
                                        <FormField
                                            name="lastName"
                                            icon={personOutline}
                                            label={t('business.signup.userForm.lastName')}
                                            placeholder={t('business.signup.userForm.lastNamePlaceholder')}
                                        />
                                        <FormField
                                            name="email"
                                            icon={mailOutline}
                                            label={t('business.signup.userForm.email')}
                                            type="email"
                                            placeholder={t('business.signup.userForm.emailPlaceholder')}
                                        />
                                        <FormField
                                            name="password"
                                            icon={lockClosedOutline}
                                            label={t('business.signup.userForm.password')}
                                            type="password"
                                            placeholder={t('business.signup.userForm.passwordPlaceholder')}
                                        />
                                        <FormField
                                            name="confirmPassword"
                                            icon={lockClosedOutline}
                                            label={t('business.signup.userForm.confirmPassword')}
                                            type="password"
                                            placeholder={t('business.signup.userForm.confirmPasswordPlaceholder')}
                                        />
                                        <FormField
                                            name="phoneNumber"
                                            icon={phonePortraitOutline}
                                            label={t('business.signup.userForm.phone')}
                                            type="tel"
                                            placeholder={t('business.signup.userForm.phonePlaceholder')}
                                        />
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="px-4 py-1.5 text-sm bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
                                        >
                                            {t('business.signup.navigation.submit')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessSignup;
