export const accessTokenName = 'access';
export const refreshTokenName = 'refresh';
export const API_BASE_URL = process.env.API_BASE_URL;

export const severityCode = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
}
