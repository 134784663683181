import {combineReducers} from 'redux'
import loadingReducer from "./loading";
import authReducer from "./authentication";
import {snackbarReducer} from "./snackbar";

const rootReducer = combineReducers({
    authentication: authReducer,
    loading: loadingReducer,
    snackbar: snackbarReducer
})

export default rootReducer
