import React, {useEffect, useRef, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {businessOutline, chevronBackOutline, homeOutline, logOutOutline, personOutline} from 'ionicons/icons';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {routePath} from "../../config/routePath";
import {useTranslation} from "react-i18next";
import {deleteJWT} from "../../libs/jwt";
import {logout} from "../../state/actions";

const Sidebar = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
    const isAuthenticated = useSelector(state => state.persistedReducer.rootReducer.authentication.isAuthenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        deleteJWT();
        dispatch(logout());
        navigate(routePath.businessLogin);
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
        setIsOpen(false);
    };


    const sidebarContents = () => {
        const contents = [
            {
                icon: homeOutline,
                title: t("dashboard.title"),
                redirectTo: routePath.dashboard,
            },
            {
                icon: personOutline,
                title: t("userProfile.title"),
                redirectTo: routePath.userProfile,
            },
            {
                icon: businessOutline,
                title: t("shops.title"),
                redirectTo: routePath.shops,
            },
        ]
        return (
            <>
                {contents.map((content, index) =>
                    <div key={index} className="mb-10 mt-16 md:mt-0 w-full">
                        <button
                            onClick={() => handleNavigation(content.redirectTo)}
                            className="flex items-center gap-x-4 w-full px-4 py-4 rounded-lg text-gray-300 hover:bg-gray-700/50 transition-colors cursor-pointer justify-center md:justify-start"
                        >
                            <IonIcon icon={content.icon} className="w-6 h-6"/>
                            <span
                                className={`${!isOpen ? 'hidden' : 'block'} whitespace-nowrap font-medium md:block`}>{content.title}</span>
                        </button>
                    </div>
                )}
            </>
        )
    }

    if (!isAuthenticated) {
        return null;
    }

    return (
        <>
            {/* Sidebar with Toggle Button Container */}
            <div className="relative">
                {/* Toggle Button - Positioned on the edge of the screen */}
                <button
                    className={`fixed ${!isOpen ? 'left-0' : 'left-64'} top-20 ml-0 
                    bg-gradient-to-r from-[#1a1f2b] to-[#252f3f] text-white 
                    border border-primary-light-border dark:border-primary-dark-border 
                    rounded-r-full p-1 shadow-md cursor-pointer z-[60] transition-all duration-300`}
                    onClick={toggleSidebar}
                >
                    <IonIcon
                        icon={chevronBackOutline}
                        className={`w-5 h-5 transition-transform ${
                            !isOpen ? 'rotate-180' : ''
                        }`}
                    />
                </button>

                {/* Sidebar */}
                <aside
                    ref={sidebarRef}
                    className={`min-h-screen bg-gradient-to-b from-[#1a1f2b] to-[#252f3f] transition-all duration-500 
                        ${isOpen ? 'w-64' : 'w-0 overflow-hidden'} 
                        fixed md:sticky top-0 left-0 z-40 shadow-lg flex flex-col items-center`}
                >
                    {/* Sidebar Content */}
                    <div
                        className={`py-6 px-2 h-full w-full flex flex-col items-center ${!isOpen ? 'hidden' : 'block'}`}>
                        {/* Main navigation items */}
                        <div className="flex-grow w-full">
                            {sidebarContents()}
                        </div>

                        {/* Logout button */}
                        <div className="w-full mt-auto mb-8">
                            <button
                                onClick={handleLogout}
                                className="flex items-center gap-x-4 w-full px-4 py-4 rounded-lg text-red-500 hover:bg-red-500 hover:text-white transition-colors cursor-pointer justify-center md:justify-start"
                            >
                                <IonIcon icon={logOutOutline} className="w-6 h-6"/>
                                <span
                                    className={`${!isOpen ? 'hidden' : 'block'} whitespace-nowrap font-medium md:block`}>
                                    {t("logout")}
                                </span>
                            </button>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    );
};

export default Sidebar;
