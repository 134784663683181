import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {apiEndpoints} from "../../config/apiEndpoints";
import {startLoading, stopLoading} from "../../state/actions";
import api from "../../libs/api";
import handleErrors from "../../libs/handleErrors";
import {setJWT} from "../../libs/jwt";
import {routePath} from "../../config/routePath";

const ActivationPage = () => {
    const {uid, token} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const activateAccount = async () => {
            dispatch(startLoading());
            try {
                const url = `${apiEndpoints.activateAccount}${uid}/${token}/`;
                const response = await api.get(url);

                if (response.status === 200) {
                    setJWT(response.data.access, response.data.refresh);
                    console.log('Account activated successfully');
                    navigate(routePath.dashboard);
                }
            } catch (error) {
                handleErrors(error, dispatch, null, null);
                navigate(routePath.businessLogin);
            } finally {
                dispatch(stopLoading());
            }
        };

        activateAccount(); // Remove the .finally()
    }, []);

    return null;
};

export default ActivationPage;
