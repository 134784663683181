import {showSnackbar} from "../state/actions";

const handleErrors = (error, dispatch = null, setFieldError = null, t) => {
    const errorResponse = error.response?.data;

    if (errorResponse) {
        if (setFieldError) {
            Object.keys(errorResponse).forEach(field => {
                if (field === "detail" || errorResponse[field] === "nonFieldErrors") {
                    return;
                }
                if (Array.isArray(errorResponse[field])) {
                    console.log('Setting field error:', field, errorResponse[field][0]);
                    setFieldError(field, errorResponse[field][0]);
                }
            });
        }
        if (dispatch) {
            const errorMessage = errorResponse.detail || errorResponse.nonFieldErrors?.[0] || errorResponse.error || t('business.signup.errors.randomFail');
            dispatch(showSnackbar(errorMessage, 'error'));
        }
    } else {
        const errorMessage = error.response?.data?.detail || error.response?.data?.nonFieldErrors?.[0] || t('business.signup.errors.randomFail');
        if (dispatch) {
            dispatch(showSnackbar(errorMessage, 'error'));
        }
    }

    console.error('Error:', error);
};

export default handleErrors;
