import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik} from 'formik';
import {loginSuccess, startLoading, stopLoading} from '../../state/actions';
import {lockClosedOutline, mailOutline} from 'ionicons/icons';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {routePath} from '../../config/routePath';
import FormField from "./FormField";
import getValidationSchema from "./validationSchema";
import {apiEndpoints} from "../../config/apiEndpoints";
import api from "../../libs/api";
import {setJWT} from "../../libs/jwt";
import handleErrors from "../../libs/handleErrors";

const BusinessLogin = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showActivateLink, setShowActivateLink] = useState(false);
    const [formValues, setFormValues] = useState({email: ''});

    const validationSchema = getValidationSchema(t);

    const handleSubmit = async (values, {setSubmitting, setFieldError}) => {
        try {
            dispatch(startLoading())
            setFormValues(values);
            const response = await api.post(apiEndpoints.login, values);
            setJWT(response.data.access, response.data.refresh);
            dispatch(loginSuccess(response.data));
            navigate(routePath.dashboard);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setShowActivateLink(true);
            }
            console.log(error);
            handleErrors(error, dispatch, setFieldError, t);
        } finally {
            dispatch(stopLoading())
            setSubmitting(false);
        }
    }
    const handleActivateAccount = async () => {
        try {
            dispatch(startLoading());
            const response = await api.post(apiEndpoints.resendActivationEmail, {
                email: formValues.email
            });
            if (response.status === 200) {
                navigate({
                    pathname: routePath.emailConfirmation,
                    search: createSearchParams({email: formValues.email}).toString()
                });
            }
        } catch (error) {
            handleErrors(error, dispatch, null, t);
        } finally {
            dispatch(stopLoading());
        }
    }

    return (
        <div
            className="min-h-[90vh] bg-white dark:bg-gray-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-primary-light-text dark:text-primary-dark-text">
                        {t('login.title')}
                    </h2>
                    <p className="mt-2 text-sm text-primary-light-text/80 dark:text-primary-dark-text/80">
                        {t('login.description')}
                    </p>
                </div>

                <Formik
                    initialValues={{email: '', password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form className="mt-8 space-y-6 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                            <div className="space-y-4">
                                <FormField
                                    name="email"
                                    icon={mailOutline}
                                    label={t('login.form.email')}
                                    type="email"
                                >
                                    <Field
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        placeholder={t('login.form.emailPlaceholder')}
                                        className="w-full px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700 text-primary-light-text dark:text-primary-dark-text border border-primary-light-border dark:border-primary-dark-border focus:ring-2 focus:ring-primary-light-tint dark:focus:ring-primary-dark-tint"
                                    />
                                </FormField>

                                <FormField
                                    name="password"
                                    icon={lockClosedOutline}
                                    label={t('login.form.password')}
                                    type="password"
                                    autoComplete="password"
                                >
                                    <Field
                                        autoComplete="password"
                                        name="password"
                                        type="password"
                                        placeholder={t('login.form.passwordPlaceholder')}
                                        className="w-full px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700 text-primary-light-text dark:text-primary-dark-text border border-primary-light-border dark:border-primary-dark-border focus:ring-2 focus:ring-primary-light-tint dark:focus:ring-primary-dark-tint"
                                    />
                                </FormField>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <button
                                        type="button"
                                        onClick={() => navigate(routePath.forgotPassword)}
                                        className="text-primary-light-tint dark:text-primary-dark-tint hover:underline"
                                    >
                                        {t('login.forgotPassword')}
                                    </button>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-light-tint dark:bg-primary-dark-tint hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                                >
                                    {t('login.form.submit')}
                                </button>
                            </div>

                            {showActivateLink && (
                                <div className="text-center mt-4">
                                    <span className="text-sm text-primary-light-text/80 dark:text-primary-dark-text/80">
                                        {t('login.accountNotActivated')}{' '}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={handleActivateAccount}
                                        className="text-sm text-primary-light-tint dark:text-primary-dark-tint hover:underline"
                                    >
                                        {t('login.activateAccount')}
                                    </button>
                                </div>
                            )}

                            <div className="text-center">
                                <span className="text-sm text-primary-light-text/80 dark:text-primary-dark-text/80">
                                    {t('login.noAccount')}{' '}
                                </span>
                                <button
                                    type="button"
                                    onClick={() => navigate(routePath.businessSignUp)}
                                    className="text-sm text-primary-light-tint dark:text-primary-dark-tint hover:underline"
                                >
                                    {t('login.signUp')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default BusinessLogin;
