import React, {useEffect, useRef} from 'react';
import {IonIcon} from '@ionic/react';
import {cashOutline, checkmarkOutline, earthOutline, leafOutline, peopleOutline, planetOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {routePath} from "../../config/routePath";
import googlePlayBadge from "../../assets/images/google-play-badge.png";
import appStoreBadge from "../../assets/images/app-store-badge.svg";

const Home = () => {
    const {t} = useTranslation();
    const sectionsRef = useRef({});

    useEffect(() => {
        // Scroll observer is removed for simplicity
    }, []);

    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="relative">
                {/* Hero Section */}
                <section
                    className="min-h-[calc(100vh-4rem)] flex flex-col items-center justify-center px-4 my-8"
                    ref={(el) => (sectionsRef.current.hero = el)}
                >
                    <div className="text-center max-w-4xl mx-auto">
                        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-primary-light-text dark:text-primary-dark-text">
                            {t('hero.title')}
                        </h1>
                        <p className="text-lg sm:text-xl mb-8 text-primary-light-text/80 dark:text-primary-dark-text/80">
                            {t('hero.description')}
                        </p>
                        <Link to={routePath.businessSignUp}
                              className="w-full sm:w-auto px-6 py-3 bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90 text-lg">
                            {t('hero.button')}
                        </Link>
                    </div>
                </section>

                {/* Cards Section */}
                <section
                    className="min-h-[calc(100vh-4rem)] flex flex-col items-center justify-center px-4 my-8"
                    ref={(el) => (sectionsRef.current.cards = el)}
                >
                    <h2 className="text-3xl sm:text-4xl font-bold text-primary-light-text dark:text-primary-dark-text mb-8 text-center">
                        {t('cards.title')}
                    </h2>
                    <div className="w-4/5 h-4/5 grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
                        {/* For Businesses */}
                        <div
                            className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg w-full h-full flex flex-col justify-center items-center">
                            <div className="flex items-center gap-3 mb-4">
                                <IonIcon icon={planetOutline}
                                         className="w-6 sm:w-8 h-6 sm:h-8 text-primary-light-tint dark:text-primary-dark-tint"/>
                                <h3 className="text-lg sm:text-xl font-bold text-primary-light-text dark:text-primary-dark-text">
                                    {t('cards.business.title')}
                                </h3>
                            </div>
                            {/* Business features list */}
                            <ul className="space-y-3 mb-4">
                                {[
                                    t('cards.business.features.0'),
                                    t('cards.business.features.1'),
                                    t('cards.business.features.2'),
                                    t('cards.business.features.3'),
                                    t('cards.business.features.4'),
                                    t('cards.business.features.5'),
                                ].map((item, index) => (
                                    <li key={index} className="flex items-center gap-2">
                                        <IonIcon icon={checkmarkOutline}
                                                 className="w-5 h-5 text-primary-light-tint dark:text-primary-dark-tint"/>
                                        <span
                                            className="text-primary-light-text dark:text-primary-dark-text">{item}</span>
                                    </li>
                                ))}
                            </ul>
                            <button
                                className="w-full py-3 bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90">
                                {t('cards.business.button')}
                            </button>
                        </div>

                        {/* For Customers */}
                        <div
                            className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg w-full h-full flex flex-col justify-center items-center">
                            <div className="flex items-center gap-3 mb-4">
                                <IonIcon icon={peopleOutline}
                                         className="w-6 sm:w-8 h-6 sm:h-8 text-primary-light-tint dark:text-primary-dark-tint"/>
                                <h3 className="text-lg sm:text-xl font-bold text-primary-light-text dark:text-primary-dark-text">
                                    {t('cards.customers.title')}
                                </h3>
                            </div>
                            {/* Customer features list */}
                            <ul className="space-y-3 mb-4">
                                {[
                                    t('cards.customers.features.0'),
                                    t('cards.customers.features.1'),
                                    t('cards.customers.features.2'),
                                    t('cards.customers.features.3'),
                                    t('cards.customers.features.4'),
                                    t('cards.customers.features.5'),
                                ].map((item, index) => (
                                    <li key={index} className="flex items-center gap-2">
                                        <IonIcon icon={checkmarkOutline}
                                                 className="w-5 h-5 text-primary-light-tint dark:text-primary-dark-tint"/>
                                        <span
                                            className="text-primary-light-text dark:text-primary-dark-text">{item}</span>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex flex-col sm:flex-row justify-center gap-4">
                                <img src={appStoreBadge} alt={t('cards.customers.appStore')}
                                     className="h-8 sm:h-10"/>
                                <img src={googlePlayBadge} alt={t('cards.customers.googlePlay')}
                                     className="h-8 sm:h-10"/>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Stats Section */}
                <section
                    className="min-h-[calc(100vh-4rem)] flex flex-col items-center justify-center px-4 my-8"
                    ref={(el) => (sectionsRef.current.stats = el)}
                >
                    <h2 className="text-3xl sm:text-4xl font-bold text-primary-light-text dark:text-primary-dark-text mb-8 text-center">
                        {t('stats.title')}
                    </h2>
                    <div className="w-4/5 h-4/5 grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8">
                        {/* Tonnes Wasted */}
                        <div
                            className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg w-full h-full flex flex-col justify-center items-center">
                            <div className="flex items-center gap-3 mb-4">
                                <IonIcon icon={earthOutline}
                                         className="w-6 sm:w-8 h-6 sm:h-8 text-primary-light-tint dark:text-primary-dark-tint"/>
                                <h3 className="text-lg sm:text-xl font-bold text-primary-light-text dark:text-primary-dark-text">
                                    {t('stats.tonnesWasted.title')}
                                </h3>
                            </div>
                            <div
                                className="text-2xl sm:text-3xl font-bold mb-2 text-primary-light-tint dark:text-primary-dark-tint">
                                {t('stats.tonnesWasted.value')}
                            </div>
                            <p className="text-base sm:text-lg font-semibold text-primary-light-text/80 dark:text-primary-dark-text/80">
                                {t('stats.tonnesWasted.description')}
                            </p>
                        </div>

                        {/* Economic Cost */}
                        <div
                            className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg w-full h-full flex flex-col justify-center items-center">
                            <div className="flex items-center gap-3 mb-4">
                                <IonIcon icon={cashOutline}
                                         className="w-6 sm:w-8 h-6 sm:h-8 text-primary-light-tint dark:text-primary-dark-tint"/>
                                <h3 className="text-lg sm:text-xl font-bold text-primary-light-text dark:text-primary-dark-text">
                                    {t('stats.economicCost.title')}
                                </h3>
                            </div>
                            <div
                                className="text-2xl sm:text-3xl font-bold mb-2 text-primary-light-tint dark:text-primary-dark-tint">
                                {t('stats.economicCost.value')}
                            </div>
                            <p className="text-base sm:text-lg font-semibold text-primary-light-text/80 dark:text-primary-dark-text/80">
                                {t('stats.economicCost.description')}
                            </p>
                        </div>

                        {/* GHG Emissions */}
                        <div
                            className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg w-full h-full flex flex-col justify-center items-center">
                            <div className="flex items-center gap-3 mb-4">
                                <IonIcon icon={leafOutline}
                                         className="w-6 sm:w-8 h-6 sm:h-8 text-primary-light-tint dark:text-primary-dark-tint"/>
                                <h3 className="text-lg sm:text-xl font-bold text-primary-light-text dark:text-primary-dark-text">
                                    {t('stats.ghgEmissions.title')}
                                </h3>
                            </div>
                            <div
                                className="text-2xl sm:text-3xl font-bold mb-2 text-primary-light-tint dark:text-primary-dark-tint">
                                {t('stats.ghgEmissions.value')}
                            </div>
                            <p className="text-base sm:text-lg font-semibold text-primary-light-text/80 dark:text-primary-dark-text/80">
                                {t('stats.ghgEmissions.description')}
                            </p>
                        </div>
                    </div>
                </section>

                {/* Join Us Section */}
                <section
                    className="h-screen flex flex-col items-center justify-center px-4 bg-primary-light-tint dark:bg-primary-dark-tint"
                    ref={(el) => (sectionsRef.current.cta = el)}
                >
                    <div className="text-center max-w-4xl mx-auto text-white">
                        <h3 className="text-3xl sm:text-4xl font-bold mb-4">{t('joinUs.title')}</h3>
                        <p className="max-w-2xl mx-auto mb-8 text-base sm:text-lg">
                            {t('joinUs.description')}
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <button
                                className="w-full sm:w-auto px-6 py-3 bg-white text-primary-light-tint dark:text-primary-dark-tint rounded-lg hover:opacity-90">
                                {t('joinUs.businessSignUp')}
                            </button>
                            <button
                                className="w-full sm:w-auto px-6 py-3 bg-transparent border-2 border-white text-white rounded-lg hover:bg-white hover:text-primary-light-tint">
                                {t('joinUs.downloadApp')}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Home;
