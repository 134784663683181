import * as types from "./actionTypes"

export const loginRequest = (credentials) => ({
    type: types.LOGIN_REQUEST,
    payload: credentials,
});

export const loginSuccess = (data) => ({
    type: types.LOGIN_SUCCESS,
    payload: data,
});

export const loginFailure = (error) => ({
    type: types.LOGIN_FAILURE,
    payload: error,
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const startLoading = () => ({type: types.START_LOADING})
export const stopLoading = () => ({type: types.STOP_LOADING})


export const showSnackbar = (message, severity = 'error', duration = 5000) => ({
    type: types.SHOW_SNACKBAR,
    payload: {message, severity, duration},
});

export const hideSnackbar = () => ({
    type: types.HIDE_SNACKBAR,
});
