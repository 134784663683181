import React from 'react';
import {IonIcon} from '@ionic/react';
import ErrorMessage from "../ErrorMessage";

const FormField = ({name, icon, label, type = "text", placeholder, children}) => (
    <div className="mb-4">
        <label className="flex items-center gap-1 text-primary-light-text dark:text-primary-dark-text mb-1 text-sm">
            <IonIcon icon={icon} className="w-4 h-4"/>
            {label}
        </label>
        {children}
        <ErrorMessage name={name}/>
    </div>
);

export default FormField;
