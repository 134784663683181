// FormField.js
import React from 'react';
import {Field} from 'formik';
import {IonIcon} from '@ionic/react';
import ErrorMessage from '../errorMessage';

const FormField = ({name, icon, label, type = "text", placeholder, as = "input", className = ""}) => (
    <div className={className}>
        <label className="flex items-center gap-1 text-primary-light-text dark:text-primary-dark-text mb-1 text-sm">
            <IonIcon icon={icon} className="w-3 h-3"/>
            {label}
        </label>
        <Field
            type={type}
            name={name}
            as={as}
            placeholder={placeholder}
            className={`w-full px-2 py-1.5 rounded-lg bg-gray-50 dark:bg-gray-700 text-primary-light-text dark:text-primary-dark-text border border-primary-light-border dark:border-primary-dark-border focus:ring-2 focus:ring-primary-light-tint dark:focus:ring-primary-dark-tint text-sm ${as === "textarea" ? "h-16 resize-none" : ""}`}
        />
        <ErrorMessage name={name}/>
    </div>
);

export default FormField;
