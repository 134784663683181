import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IonIcon} from '@ionic/react';
import {closeOutline, menuOutline} from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import ThemeToggle from '../ThemeToggle';
import LanguageSwitcher from '../LanguageSwitcher';
import {routePath} from '../../config/routePath';
import logoImg from '../../assets/logos/logo-color.png';

const NavBar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.persistedReducer.rootReducer.authentication.isAuthenticated);
    const closeMenu = () => setIsMobileMenuOpen(false);

    const AuthenticatedLinks = () => (
        <div className="relative">
            {isProfileMenuOpen && (
                <div
                    className="absolute right-0 mt-2 w-48 bg-white/80 dark:bg-gray-900/80 backdrop-blur-md rounded-lg shadow-lg border border-primary-light-border dark:border-primary-dark-border">
                </div>
            )}
        </div>
    );

    const PublicLinks = () => (
        <NavLink
            to={routePath.businessLogin}
            className="px-4 py-2 bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90 transition-opacity"
        >
            {t('navbar.businessLogin')}
        </NavLink>
    );

    return (
        <nav
            className="sticky top-0 z-50 w-full bg-white/80 dark:bg-gray-900/80 backdrop-blur-md border-primary-light-border dark:border-primary-dark-border">
            <div className="w-full">
                <div className="flex items-center justify-between h-16">
                    {/* Logo/Brand */}
                    <div className="pl-4">
                        <NavLink to={isAuthenticated ? routePath.dashboard : routePath.home}
                                 className="flex items-center">
                            <img
                                src={logoImg}
                                alt="Logo"
                                className="h-12 w-auto object-contain transition-transform hover:scale-105"
                            />
                        </NavLink>
                    </div>

                    {/* Navigation Links */}
                    <div className="hidden md:flex items-center space-x-8 pr-4">
                        {isAuthenticated ? <AuthenticatedLinks/> : <PublicLinks/>}
                        <ThemeToggle/>
                        <LanguageSwitcher/>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden p-2 mr-4 rounded-lg text-primary-light-text dark:text-primary-dark-text hover:bg-gray-100 dark:hover:bg-gray-800"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        <IonIcon
                            icon={isMobileMenuOpen ? closeOutline : menuOutline}
                            className="w-6 h-6"
                        />
                    </button>
                </div>

                {/* Mobile Menu */}
                <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        {!isAuthenticated && (
                            <NavLink
                                onClick={closeMenu}
                                to={routePath.businessLogin}
                                className="block w-full text-center px-4 py-2 bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90 transition-opacity"
                            >
                                {t('navbar.businessLogin')}
                            </NavLink>
                        )}
                        <div className="px-3 py-2 flex">
                            <ThemeToggle/>
                        </div>
                        <LanguageSwitcher/>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
