import React, {useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {moonOutline, sunnyOutline} from 'ionicons/icons';
import {useTranslation} from "react-i18next";

const ThemeToggle = () => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [mounted, setMounted] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setMounted(true);
        if (!localStorage.getItem('theme')) {
            const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            setTheme(systemTheme);
        }
    }, []);

    useEffect(() => {
        if (!mounted) return;
        localStorage.setItem('theme', theme);
        document.documentElement.classList.toggle('dark', theme === 'dark');
    }, [theme, mounted]);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    if (!mounted) return null;

    return (
        <div className="flex items-center gap-2">
            <span className="block sm:hidden text-sm font-medium dark:text-white">
                {t('navbar.darkMode')}
            </span>
            <button
                onClick={toggleTheme}
                className="relative inline-flex h-6 w-11 items-center rounded-full bg-slate-200 transition-colors dark:bg-slate-700"
                role="switch"
                aria-checked={theme === 'dark'}
            >
                <span
                    className={`${
                        theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 dark:bg-gray-900`}
                >
                    <span className="absolute inset-0 flex items-center justify-center">
                        <IonIcon
                            icon={theme === 'dark' ? moonOutline : sunnyOutline}
                            className={`h-3 w-3 ${
                                theme === 'dark' ? 'text-blue-100' : 'text-black-50'
                            }`}
                        />
                    </span>
                </span>
            </button>
        </div>
    );
};

export default ThemeToggle;
