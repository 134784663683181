// reducers/authReducer.js
import {LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT} from '../actionTypes';
import {accessTokenName, refreshTokenName} from "../../config/constants";

const initialState = {
    [accessTokenName]: null,
    [refreshTokenName]: null,
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                [accessTokenName]: action.payload.access,
                [refreshTokenName]: action.payload.refresh,
                isAuthenticated: true,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                [accessTokenName]: null,
                [refreshTokenName]: null,
                isAuthenticated: false,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
