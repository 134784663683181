import React from 'react';

const StylishedSaveButton = ({children, type = 'button', disabled}) => {
    return (
        <button
            type={type}
            disabled={disabled}
            className={`px-6 py-3 font-bold rounded-lg transition-colors duration-200 
            bg-primary-600 text-gray-900 dark:bg-primary-700 dark:text-white
            hover:bg-primary-700 dark:hover:bg-primary-800
            disabled:opacity-50 disabled:cursor-not-allowed shadow-md focus:outline-none`}
        >
            {children}
        </button>
    );
};

export default StylishedSaveButton;
