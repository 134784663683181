import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import api from '../../libs/api';
import {routePath} from '../../config/routePath';
import {logout} from '../../state/actions';
import {accessTokenName} from '../../config/constants';

const PrivateRoute = ({children}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleLogout = () => {
            localStorage.removeItem(accessTokenName);
            dispatch(logout());
            navigate(routePath.businessLogin);
        };

        // Add response interceptor
        const interceptor = api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    handleLogout();
                }
                return Promise.reject(error);
            }
        );

        // Check if token exists
        const token = localStorage.getItem(accessTokenName);
        if (!token) {
            handleLogout();
        }

        // Cleanup interceptor on unmount
        return () => {
            api.interceptors.response.eject(interceptor);
        };
    }, [navigate, dispatch]);

    return <div className="flex-1">{children}</div>;
};

export default PrivateRoute;
