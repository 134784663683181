import React from 'react';
import {useSelector} from "react-redux";

const Loading = () => {
    const loading = useSelector(state => state.persistedReducer.rootReducer.loading)
    if (!loading) {
        return null
    }
    return (
        <div
            className="fixed inset-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="flex flex-col items-center justify-center gap-4">
                <div className="w-12 h-12 animate-spin">
                    <svg
                        className="text-primary-light-tint dark:text-primary-dark-tint"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );

};

export default Loading;
