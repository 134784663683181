import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import api from '../../libs/api';
import handleErrors from '../../libs/handleErrors';
import {showSnackbar, startLoading, stopLoading} from '../../state/actions';
import {apiEndpoints} from "../../config/apiEndpoints";
import FormField from '../../components/FormField';
import StylishedSaveButton from "../../components/StylishedSaveButton";

const UserProfile = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [profile, setProfile] = useState(null);
    const loading = useSelector(state => state.persistedReducer.rootReducer.loading);

    const validationSchema = Yup.object().shape({
        address: Yup.string().min(5, t('userProfile.validation.addressMin')),
        phoneNumber: Yup.string()
            .matches(/^[0-9]+$/, t('userProfile.validation.phoneNumberFormat'))
            .min(10, t('userProfile.validation.phoneNumberLength')),
        dob: Yup.date()
            .max(new Date(), t('userProfile.validation.dobPast'))
            .nullable(),
        gender: Yup.string().oneOf(['M', 'F', 'O', 'P'], t('userProfile.validation.gender')),
        country: Yup.string().min(2, t('userProfile.validation.country')),
        darkMode: Yup.string().oneOf(['L', 'D'], t('userProfile.validation.darkMode'))
    });

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            dispatch(startLoading());
            const response = await api.get(apiEndpoints.userProfile);
            setProfile(response.data);
        } catch (err) {
            handleErrors(err, dispatch, null, t);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleSubmit = async (values, {setSubmitting, setFieldError}) => {
        try {
            dispatch(startLoading());
            const formattedValues = {
                ...values,
                dob: values.dob ? new Date(values.dob).toISOString().split('T')[0] : null,
            };
            const response = await api.put(apiEndpoints.userProfile, formattedValues);
            setProfile(response.data);
            dispatch(showSnackbar(t('userProfile.updateSuccess'), 'success'));
        } catch (err) {
            handleErrors(err, dispatch, setFieldError, t);
        } finally {
            dispatch(stopLoading());
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-primary-600"></div>
            </div>
        );
    }

    const initialValues = {
        address: profile?.address || '',
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        phoneNumber: profile?.phoneNumber || '',
        dob: profile?.dob ? new Date(profile.dob) : null,
        gender: profile?.gender || '',
        country: profile?.country || '',
        darkMode: profile?.darkMode || 'D'
    };

    const fields = (t) => [
        {
            name: 'firstName',
            label: t('userProfile.firstName'),
            type: 'text',
        },
        {
            name: 'lastName',
            label: t('userProfile.lastName'),
            type: 'text',
        },
        {
            name: 'address',
            label: t('userProfile.address'),
            type: 'textarea',
        },
        {
            name: 'phoneNumber',
            label: t('userProfile.phoneNumber'),
            type: 'tel',
        },
        {
            name: 'dob',
            label: t('userProfile.dob'),
            type: 'date',
            placeholder: t('userProfile.dobPlaceholder'),
        },
        {
            name: 'gender',
            label: t('userProfile.genderTitle'),
            type: 'select',
            options: [
                {value: 'M', label: t('userProfile.gender.male')},
                {value: 'F', label: t('userProfile.gender.female')},
                {value: 'O', label: t('userProfile.gender.other')},
                {value: 'P', label: t('userProfile.gender.preferNotToSay')},
            ],
            placeholder: t('common.select'),
        },
        {
            name: 'darkMode',
            label: t('userProfile.darkModeTitle'),
            type: 'select',
            options: [
                {value: 'L', label: t('userProfile.darkMode.light')},
                {value: 'D', label: t('userProfile.darkMode.dark')},
            ],
            placeholder: t('common.select'),
        },
    ];

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white text-center">
                        {t('userProfile.title')}
                    </h2>
                </div>

                <div className="p-6">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({errors, touched, isSubmitting, setFieldValue, values}) => (
                            <Form className="space-y-6">
                                {fields(t).map((fieldConfig) => (
                                    <FormField
                                        key={fieldConfig.name}
                                        fieldConfig={fieldConfig}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                ))}

                                <div className="flex justify-center pt-8">
                                    <StylishedSaveButton type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? t('common.saving') : t('common.save')}
                                    </StylishedSaveButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
