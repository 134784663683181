// api.js
import axios from 'axios';
import {accessTokenName, API_BASE_URL, refreshTokenName} from "../config/constants";
import {routePath} from "../config/routePath";
import {apiEndpoints} from "../config/apiEndpoints";

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

// Attach token to requests if available
api.interceptors.request.use((config) => {
    const token = localStorage.getItem(accessTokenName); // Assuming token is stored in localStorage
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    const language = localStorage.getItem('i18nextLng') || 'en'; // Retrieve the current language
    config.headers['Accept-Language'] = language;
    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const isRefreshTokenRequest = originalRequest.url.includes(apiEndpoints.tokenRefresh);

        // If error is not 401 or we're already retrying, reject
        const shouldRefreshToken = error.response?.status === 401 && originalRequest._retry && !isRefreshTokenRequest && !originalRequest._retry
        if (!shouldRefreshToken) {
            return Promise.reject(error);
        }

        originalRequest._retry = true;

        try {
            // Attempt to refresh the token
            const refreshToken = localStorage.getItem(refreshTokenName);
            const response = await api.post(apiEndpoints.tokenRefresh, {
                refresh: refreshToken,
            });

            // Store the new access token
            const {access} = response.data;
            localStorage.setItem(accessTokenName, access);

            // Update the failed request's authorization header
            originalRequest.headers.Authorization = `Bearer ${access}`;

            // Retry the failed request with new token
            return api(originalRequest);
        } catch (refreshError) {
            // If refresh fails, clear tokens and redirect to login
            localStorage.removeItem(accessTokenName);
            localStorage.removeItem(refreshTokenName);
            window.location.href = routePath.businessLogin;
            return Promise.reject(refreshError);
        }
    }
);

export default api;
