import React from "react";
import {RouterProvider} from "react-router-dom";
import rootRouter from "./routers/rootRouter";
import {I18nextProvider} from "react-i18next";
import i18n from './i18n';
import {Provider} from 'react-redux'
import {persistor, store} from './state/store'
import {PersistGate} from "redux-persist/integration/react";

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18n}>
                    <RouterProvider router={rootRouter}/>
                </I18nextProvider>
            </PersistGate>
        </Provider>
    );
}

export default App
