import * as types from "../actionTypes"
import {severityCode} from "../../config/constants";


const initialState = {
    open: false,
    message: '',
    severity: severityCode.ERROR,
    duration: 5000,
};

export const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_SNACKBAR:
            return {
                ...state,
                open: true,
                message: action.payload.message,
                severity: action.payload.severity,
                duration: action.payload.duration,
            };
        case types.HIDE_SNACKBAR:
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
};
