export const routePath = {
    home: "/",
    termsOfUse: "/terms-of-use",
    contactUs: "/contact-us",
    notFound: "/not-found",
    privacyPolicy: "/privacy-policy",
    businessLogin: "/business-login",
    businessSignUp: "/business-sign-up",
    dashboard: "/dashboard",
    emailConfirmation: "/email-confirmation",
    activationPage: "/activate/:uid/:token",
    userProfile: "/user-profile",
    shops: "/shops",
}
