import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import notFoundImage from "../assets/images/not-found.png";

const NotFound = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-900">
            {/* Image */}
            <img src={notFoundImage} alt="Not Found"
                 className="w-80 h-auto object-contain mb-8 transition-transform hover:scale-105"/>

            {/* Message */}
            <h1 className="text-3xl font-bold text-primary-light-text dark:text-primary-dark-text mb-4">
                {t('not-found.title')}
            </h1>
            <p className="text-lg text-primary-light-text dark:text-primary-dark-text mb-8">
                {t('not-found.message')}
            </p>

            {/* Button to Home */}
            <NavLink to="/"
                     className="px-6 py-3 bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg hover:opacity-90 transition-opacity">
                {t('not-found.button')}
            </NavLink>
        </div>
    );
};

export default NotFound;
