import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {mailOutline} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';
import {apiEndpoints} from '../../config/apiEndpoints';
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../../state/actions";
import api from "../../libs/api";
import handleErrors from "../../libs/handleErrors";

const EmailConfirmation = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const [countdown, setCountdown] = useState(0);
    const loading = useSelector(state => state.persistedReducer.rootReducer.loading);
    const dispatch = useDispatch();

    // Countdown effect to decrease every second
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    // Handle the resend email button click
    const handleResendEmail = useCallback(async () => {
        // Check if button is in countdown mode or loading
        if (countdown > 0 || loading) return;

        dispatch(startLoading());
        try {
            const response = await api.post(apiEndpoints.resendActivationEmail, {
                email: email
            });

            if (response.status === 200) {
                const data = await response.data; // Await response.json() directly
                setCountdown(60); // Start 60 seconds countdown on successful request
                console.log(data.message);
            }
        } catch (error) {
            handleErrors(error, dispatch, null, t);
            console.error('Failed to resend activation email:', error);
        } finally {
            dispatch(stopLoading());
        }
    }, [email, countdown, loading, dispatch]);

    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="min-h-[90vh] flex items-center justify-center px-4 pb-16">
                <div className="w-full max-w-2xl">
                    <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg text-center">
                        <div className="flex justify-center mb-6">
                            <div
                                className="w-20 h-20 rounded-full bg-primary-light-tint/10 dark:bg-primary-dark-tint/10 flex items-center justify-center">
                                <IonIcon
                                    icon={mailOutline}
                                    className="w-10 h-10 text-primary-light-tint dark:text-primary-dark-tint"
                                />
                            </div>
                        </div>

                        <h1 className="text-2xl font-bold mb-4 text-primary-light-text dark:text-primary-dark-text">
                            {t('emailConfirmation.title')}: {email}
                        </h1>

                        <p className="text-primary-light-text/80 dark:text-primary-dark-text/80 mb-6">
                            {t('emailConfirmation.description')}
                        </p>

                        <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-lg mb-6">
                            <p className="text-sm text-primary-light-text/70 dark:text-primary-dark-text/70">
                                {t('emailConfirmation.instructions')}
                            </p>
                        </div>

                        <div className="flex flex-col items-center space-y-2">

                            <button
                                onClick={handleResendEmail}
                                disabled={countdown > 0 || loading}
                                className={`px-6 py-2 text-sm bg-primary-light-tint dark:bg-primary-dark-tint text-white rounded-lg transition-all ${
                                    countdown > 0 || loading
                                        ? 'cursor-not-allowed'
                                        : 'hover:opacity-90'
                                }`}
                            >
                                {loading
                                    ? t('emailConfirmation.sending')
                                    : countdown > 0
                                        ? `${t('emailConfirmation.resendIn', {time: countdown})}`
                                        : t('emailConfirmation.resend')}
                            </button>

                            <button
                                onClick={() => navigate('/')}
                                className="px-6 py-2 text-sm bg-transparent border border-primary-light-tint dark:border-primary-dark-tint text-primary-light-tint dark:text-primary-dark-tint rounded-lg hover:bg-primary-light-tint/10 dark:hover:bg-primary-dark-tint/10 transition-colors"
                            >
                                {t('emailConfirmation.back')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmation;
