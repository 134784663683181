import React from "react";
import {createBrowserRouter, Outlet} from "react-router-dom";
import Home from "../views/home";
import NavBar from "../components/NavBar";
import {routePath} from "../config/routePath";
import TermsOfUse from "../views/termsOfUse";
import NotFound from "../components/NotFound";
import Footer from "../components/Footer";
import BusinessSignup from "../views/businessSignUp";
import BusinessLogin from "../views/businessLogin";
import Dashboard from "../views/Dashboard";
import Loading from "../components/Loading";
import Snackbar from "../components/SnackBar";
import EmailConfirmation from "../views/emailConfirmation";
import ActivationPage from "../views/activationPage";
import PrivateRoute from "../components/PrivateRoute";
import Sidebar from "../components/Sidebar";
import UserProfile from "../views/UserProfile";
import Shops from "../views/Shops";

const Root = () => {
    return (
        <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors flex">
            <Sidebar/>
            <div className="flex-1 flex flex-col min-h-screen md:ml-0">
                <NavBar/>
                <main className="flex-1 p-8">
                    <Outlet/>
                </main>
                <Footer/>
            </div>
            <Loading/>
            <Snackbar/>
        </div>
    );
};
const PrivateOutlet = () => {
    return (
        <PrivateRoute>
            <Outlet/>
        </PrivateRoute>
    );
};

const rootRouter = new createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <NotFound/>,
        children: [
            {
                path: "",
                element: <Home/>
            },
            {
                path: routePath.termsOfUse,
                element: <TermsOfUse/>
            },
            {
                path: routePath.businessSignUp,
                element: <BusinessSignup/>
            },
            {
                path: routePath.businessLogin,
                element: <BusinessLogin/>
            },
            {
                element: <PrivateOutlet/>,
                children: [
                    {
                        path: routePath.dashboard,
                        element: <Dashboard/>
                    },
                ]
            },
            {
                element: <PrivateOutlet/>,
                children: [
                    {
                        path: routePath.userProfile,
                        element: <UserProfile/>
                    }
                ]
            },
            {
                element: <PrivateOutlet/>,
                children: [
                    {
                        path: routePath.shops,
                        element: <Shops/>
                    }
                ]
            },
            {
                path: `${routePath.emailConfirmation}`,
                element: <EmailConfirmation/>
            },
            {
                path: routePath.activationPage,
                element: <ActivationPage/>
            }
        ]
    }
]);

export default rootRouter;
