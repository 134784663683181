// src/i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import grTranslation from './locales/gr/translation.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {translation: enTranslation},
            el: {translation: grTranslation}
        },
        lng: localStorage.getItem('i18nextLng') || 'en',
        fallbackLng: 'el',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }).finally();

export default i18n;
