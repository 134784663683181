import {accessTokenName, refreshTokenName} from "../config/constants";

const setJWT = (access, refresh) => {
    localStorage.setItem(accessTokenName, access);
    localStorage.setItem(refreshTokenName, refresh);
}

const getJWT = () => {
    return {
        access: localStorage.getItem(accessTokenName),
        refresh: localStorage.getItem(refreshTokenName)
    }
}
const deleteJWT = () => {
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
}
export {setJWT, getJWT, deleteJWT};
