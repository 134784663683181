import createSagaMiddleware from 'redux-saga'
import {combineReducers} from 'redux'
import {configureStore} from '@reduxjs/toolkit'
import {rootSaga} from './sagas'
import rootReducer from "./reducers";
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from "redux-persist";

const persistConfig = {
    key: 'root',
    storage,
};
const persistedReducer = persistReducer(persistConfig, combineReducers({
    rootReducer,
}));

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: combineReducers({
        rootReducer,
        persistedReducer
    }),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
})

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
